import Articles from '../routes/articles';
// import { getTrendingCommunities } from '../services/community';
// import { initialState, LIMIT } from '../ducks/article';
// import { getArticles, getPagedArticles } from '../services/article';

// if (typeof window === 'undefined') {
//     globalThis.Promise = require('bluebird');
// }

// export async function getInitialProps(context) {
//     const { query } = context;
//     const pageNum = query.pageNum;
//     const isPaginationMode = /^\d+$/.test(pageNum);
//     return Promise.all([
//         getTrendingCommunities(),
//         isPaginationMode ? getPagedArticles({
//             offset: (+pageNum - 1) * LIMIT,
//             limit: LIMIT,
//             sort: initialState.sort,
//         }) : getArticles({
//             init: true,
//             sort: initialState.sort,
//             limit: LIMIT,
//         })
//     ]).then(([res1, res2]) => {
//         // getTrendingCommunities
//         let state = {
//             ...initialState,
//             trendingCommunities: res1.communities,
//             isCommunityInited: true
//         };
//         console.log('res2', res2);

//         // getPagedArticles
//         // getArticles
//         const {
//             hasMore,
//             cursor,
//             pageNum,
//             total,
//             articles,
//         } = res2;
//         state = {
//             ...state,
//             hasMore,
//             ...(cursor && { cursor }),
//             ...(+pageNum && total && {
//                 pagination: {
//                     total: Math.ceil(total / LIMIT),
//                     currentPage: +pageNum,
//                 },
//             }),
//             articles,
//             isArticleInited: true,
//         }
//         return {
//             initialReduxState: {
//                 article: {
//                     ...state,
//                 }
//             }
//         };
//     }).catch((err) => {
//         // fallback to csr
//         console.log('whats wrong', err);
//         return {
//             initialReduxState: {
//                 article: initialState
//             }
//         }
//     });
// }

export default Articles;
