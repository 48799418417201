import React from 'react';
import PropTypes from 'prop-types';
// import LazyLoad from 'react-lazyload';
import { MdModeComment } from '@react-icons/all-files/md/MdModeComment';
import { GoArrowUp } from '@react-icons/all-files/go/GoArrowUp';
import { GoArrowDown } from '@react-icons/all-files/go/GoArrowDown';
import h2t from 'html2plaintext';
// import Image from 'next/image';
import Image from '../image';
import Link from '../link';
import NextLink from '../nextLink';
import timeFormatter from '../../utils/timeFormatter';
import numberFormatter from '../../utils/numberFormatter';
import extractImage from '../../utils/extractImage';
// import formatImageSrc from '../../utils/formatImageSrc';
import styles from './index.module.css';

const PostItem = ({
    id,
    title,
    content,
    createdAt,
    communityInfo,
    // headerImage,
    // tags,
    upvotes,
    downvotes,
    subpostCount,
    isDesktop,
    onPress,
}) => {
    const subtitle = content ? h2t(content).substring(0, 200) : '';

    const firstImage = extractImage(content);

    // const firstImageStyle = firstImage ? {
    //     backgroundImage: `url(${formatImageSrc({
    //         src: headerImage,
    //         width: isDesktop ? 200 : 100,
    //     })}`,
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     backgroundRepeat: 'no-repeat',
    // } : {};

    const communityLink = communityInfo.name ? `/c/${communityInfo.name}` : '';

    // const hasBottomInfo = upvotes || downvotes || subpostCount;

    const subtitleLines = 2;

    // if (isDesktop && !hasBottomInfo) {
    //     subtitleLines += 1;
    // }

    return (
        <div>
            <div
                role="button"
                tabIndex={0}
                className={styles.container}
                onClick={onPress}
                onKeyDown={onPress}
            >
                <div className={`${styles['info-container']} ${firstImage.src ? styles[isDesktop ? 'desktop' : 'mobile'] : ''}`}>
                    {
                        communityInfo.name && (
                            <div className={`${styles['community-info']}`}>
                                {
                                    communityInfo.iconImage && (
                                        <Link
                                            className={styles['community-icon']}
                                            grayVisited={false}
                                            title={communityInfo.alias}
                                            to={communityLink}
                                        >
                                            <Image
                                                src={communityInfo.iconImage}
                                                width={20}
                                                height={20}
                                            />
                                        </Link>
                                    )
                                }
                                <div className={`${styles['community-alias']} ${styles['line-ellipsis-single']}`}>
                                    <Link
                                        className={styles['community-alias-link']}
                                        grayVisited={false}
                                        title={communityInfo.alias}
                                        to={communityLink}
                                    >
                                        {communityInfo.alias}
                                    </Link>
                                </div>
                                <div className={styles.dot}>·</div>
                                <div className={`${styles['created-at']}`}>
                                    {timeFormatter(createdAt, true)}
                                </div>
                            </div>
                        )
                    }
                    <div className={`${styles.title} ${styles['line-ellipsis']}`}>
                        {
                            communityInfo.name && (
                                <NextLink
                                    linkProps={{ href: `/c/${communityInfo.name}/p/${id}` }}
                                    anchorProps={{ onClick: onPress }}
                                >
                                    {title}
                                </NextLink>
                                // <Link to={`/c/${communityInfo.name}/p/${id}`}>{title}</Link>
                            )
                        }
                    </div>
                    {
                        subtitle && (
                            <div className={`${styles[isDesktop ? 'desktop' : 'mobile']} ${styles.subtitle} ${styles['line-ellipsis']} ${subtitleLines > 2 ? styles[`expand${subtitleLines}`] : ''}`}>
                                {subtitle}
                            </div>
                        )
                    }
                    <div className={styles['bottom-info-wrapper']}>
                        <div className={styles['bottom-info-container']}>
                            {/* <div className={styles['created-at']}>
                                {timeFormatter(createdAt)}
                            </div> */}
                            <div className={styles['sub-info-container']}>
                                {
                                    upvotes ? (
                                        <>
                                            <GoArrowUp
                                                className={styles['info-icon']}
                                                color="#757575"
                                                size={20}
                                                style={{
                                                    marginLeft: -4,
                                                    marginRight: -4,
                                                    marginBottom: -2.5,
                                                }}
                                            />
                                            <span className={styles.number} title={upvotes}>
                                                {numberFormatter(upvotes, 1, true)}
                                            </span>
                                        </>
                                    ) : null
                                }
                                {
                                    downvotes ? (
                                        <>
                                            <GoArrowDown
                                                className={styles['info-icon']}
                                                color="#757575"
                                                size={20}
                                                style={{
                                                    marginLeft: -4,
                                                    marginRight: -4,
                                                    marginBottom: -3,
                                                }}
                                            />
                                            <span className={styles.number} title={downvotes}>
                                                {numberFormatter(downvotes, 1, true)}
                                            </span>
                                        </>
                                    ) : null
                                }
                                {
                                    subpostCount ? (
                                        <>
                                            <MdModeComment
                                                className={styles['info-icon']}
                                                style={{
                                                    transform: 'rotateY(180deg)',
                                                    // marginLeft: upvotes ? 15 : 0,
                                                }}
                                                color="#757575"
                                                size={14}
                                            />
                                            <span className={styles.number} title={subpostCount}>
                                                {numberFormatter(subpostCount, 1, true)}
                                            </span>
                                        </>
                                    ) : null
                                }
                                {/* {
                                    !upvotes ? (
                                        <div className={styles['icon-number-container']}>
                                            <GoArrowUp color="#757575" size={18} />
                                            <span className={styles.number}>{ upvotes }</span>
                                        </div>
                                    ) : null
                                }
                                {
                                    !commentCount ? (
                                        <div className={styles['icon-number-container']}>
                                            <div className={styles['comment-icon']}>
                                                <GoComment color="#757575" size={11} />
                                            </div>
                                            <span className={styles.number}>{ commentCount }</span>
                                        </div>
                                    ) : null
                                } */}
                            </div>
                        </div>
                    </div>
                    {/* {
                        tags.length > 0 ? (
                            <div className={styles.tag}>
                                {tags[0].name}
                            </div>
                        ) : null
                    } */}
                </div>
                {
                    firstImage.src && (
                        <div>
                            <div className={`${styles['header-image']} ${isDesktop ? '' : styles.mobile}`}>
                                <Image
                                    src={firstImage.src}
                                    // alt={firstImage.src}
                                    width={isDesktop ? 200 : 100}
                                    height={isDesktop ? 133 : 100}
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    )
                    // firstImage && (
                    //     <LazyLoad height={isDesktop ? 133 : 100}>
                    //         <div
                    //             className={
                    //                 `${styles['header-image']} ${isDesktop ? '' : styles.mobile}`
                    //             }
                    //             style={headerImageStyle}
                    //         />
                    //     </LazyLoad>
                    // )
                }
            </div>
        </div>
    );
};

PostItem.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    creator: PropTypes.shape({
        nickname: PropTypes.string,
    }),
    content: PropTypes.string,
    createdAt: PropTypes.string,
    communityInfo: PropTypes.shape({
        name: PropTypes.string,
        alias: PropTypes.string,
        iconImage: PropTypes.string,
    }),
    // headerImage: PropTypes.string,
    // tags: PropTypes.arrayOf(PropTypes.shape({
    //     name: PropTypes.string,
    // })),
    upvotes: PropTypes.number,
    downvotes: PropTypes.number,
    subpostCount: PropTypes.number,
    isDesktop: PropTypes.bool,
    onPress: PropTypes.func,
};

PostItem.defaultProps = {
    id: '',
    title: '',
    creator: {
        nickname: '',
    },
    content: '',
    createdAt: '',
    communityInfo: {
        name: '',
        alias: '',
        iconImage: '',
    },
    // headerImage: '',
    // tags: [],
    upvotes: 0,
    downvotes: 0,
    subpostCount: 0,
    isDesktop: true,
    onPress: () => { },
};

export default PostItem;
