import React from 'react';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import { useRouter } from 'next/router';
import { IoIosTrendingUp } from '@react-icons/all-files/io/IoIosTrendingUp';
// import Image from 'next/image';
import Image from '../image';
import Link from '../link';
import styles from './index.module.css';
import numberFormatter from '../../utils/numberFormatter';

const CommunityInfo = ({
    alias,
    name,
    iconImage,
    description,
    trendingScore,
    rank,
    isDesktop,
}) => {
    console.log(isDesktop, trendingScore);
    const router = useRouter();

    const toCommunity = () => {
        router.push(`/c/${name}`);
    };

    return (
        <div
            className={styles.container}
            onClick={toCommunity}
            onKeyDown={toCommunity}
            role="button"
            tabIndex={0}
        >
            <div className={`${styles.rank} ${isDesktop ? '' : styles.mobile}`}>
                {`0${rank}`}
            </div>
            <div className={styles.info}>
                <div className={styles['icon-name']}>
                    {
                        iconImage && (
                            <div className={styles.icon}>
                                <Image
                                    // className={styles.icon}
                                    src={iconImage}
                                    width={30}
                                    height={30}
                                    alt="icon"
                                />
                            </div>
                        )
                    }
                    <div className={styles.alias}>
                        <Link grayVisited={false} to={`/c/${name}`}>{ alias }</Link>
                    </div>
                </div>
                {
                    isDesktop && description && (
                        <div className={`${styles.description} ${styles['line-ellipsis']}`}>
                            { description}
                        </div>
                    )
                }
                {
                    isDesktop && trendingScore ? (
                        <div className={styles['trending-score']}>
                            <IoIosTrendingUp size={14} color="#292929" />
                            <div className={styles.score}>
                                {numberFormatter(trendingScore)}
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
};

CommunityInfo.propTypes = {
    alias: PropTypes.string,
    name: PropTypes.string,
    iconImage: PropTypes.string,
    description: PropTypes.string,
    trendingScore: PropTypes.number,
    rank: PropTypes.number,
    isDesktop: PropTypes.bool,
};

CommunityInfo.defaultProps = {
    alias: '',
    name: '',
    iconImage: '',
    description: '',
    trendingScore: 0,
    rank: 0,
    isDesktop: true,
};

export default CommunityInfo;
