import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { useHistory, useLocation } from 'react-router-dom';
// import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
// import Image from 'next/image'
import { connect } from 'react-redux';
import Image from '../../components/image';
import { wrapper } from '../../store';
import * as articleActions from '../../ducks/article';
import * as notificationActions from '../../ducks/notification';
import getUserInfo from '../../utils/getUserInfo';
import { useResponsive } from '../../utils/mediaQuery';
import useScrollRestoration from '../../utils/useScrollRestoration';
import to2d from '../../utils/to2d';
import PostItem from '../../components/postItem';
import Helmet from '../../components/helmet';
import Loader from '../../components/loader';
import Button from '../../components/button';
import SideFooter from '../../components/sideFooter';
import CommunityInfo from '../../components/communityInfo';
import CustomLink from '../../components/link';
import NextLink from '../../components/nextLink';
import Pagination from '../../components/pagination';
import PaginationModeIcon from '../../components/paginationModeIcon';
import styles from './index.module.css';
import LeavesImage from '../../../public/leaves.svg';

const BannerAspectRatio = 227 / 111;
const BannerHeight = 150;
const BannerWidth = BannerAspectRatio * BannerHeight;

const Articles = ({
    trendingCommunities,
    posts,
    hasMore,
    sort,
    pagination,
    getPosts,
    getPagedPosts,
    // getTrendingCommunities,
    countNotifications,
    isLoggedIn,
    dispatch,
    // isPostInited,
    // isCommunityInited,
}) => {
    // console.log('update', articles);
    const { isDesktop } = useResponsive();
    // TODO: fetch

    // useEffect(() => {
    //     getTrendingCommunities();
    // }, [getTrendingCommunities]);

    useEffect(() => {
        if (isLoggedIn) {
            countNotifications();
        }
    }, [isLoggedIn, countNotifications]);

    // TODO: fetch
    // pagination
    const router = useRouter();
    useScrollRestoration(router);

    const urlParams = router.query;
    const { pageNum } = urlParams;

    const isPaginationMode = /^\d+$/.test(pageNum);

    // useEffect(() => {
    //     if (isPaginationMode) {
    //         getPagedArticles({
    //             pageNum: +pageNum,
    //         });
    //     }
    // }, [getPagedArticles, pageNum, isPaginationMode]);

    // cursor
    // useEffect(() => {
    //     if (!isPaginationMode) {
    //         getArticles({
    //             init: true,
    //         });
    //     }
    // }, [getArticles, isPaginationMode]);

    const banner = useRef();

    const bannerObserver = useRef();

    useEffect(() => {
        if (!bannerObserver.current) {
            bannerObserver.current = new IntersectionObserver(
                ([entry]) => {
                    dispatch({
                        type: 'header/SET_THEME',
                        payload: {
                            theme: entry.isIntersecting ? 'banner' : 'normal',
                        },
                    });
                },
                {
                    rootMargin: '-50px 0px 0px 0px',
                },
            );
        }
        bannerObserver.current.observe(banner.current);
        return () => {
            bannerObserver.current.disconnect();
            dispatch({
                type: 'header/SET_THEME',
                payload: {
                    theme: 'normal',
                },
            });
        };
    }, []);

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            getPosts({
                init: false,
            });
        }
    };

    const observer = useRef();
    const registerObserver = (node) => {
        if (!observer.current) {
            observer.current = new IntersectionObserver(handleObserver, {
                root: null,
                rootMargin: '0px',
                threshold: 0.9,
            });
        }
        observer.current.disconnect();
        if (node) {
            observer.current.observe(node);
        }
    };

    const loaderRef = useCallback((node) => {
        registerObserver(node);
    }, []);

    // const history = useHistory();

    const changeSortType = (tab) => {
        dispatch({
            type: 'article/SET_STATE',
            payload: {
                sort: tab,
                cursor: {},
            },
        });
        if (isPaginationMode) {
            if (pagination.currentPage === 1) {
                getPagedPosts({
                    pageNum: +pageNum,
                });
            } else {
                router.push('/?pageNum=1');
            }
        } else {
            getPosts({
                init: true,
            });
        }
    };

    const toPost = (post) => {
        const postLink = `/c/${post.communityInfo.name}/p/${post.id}`;
        if (isDesktop) {
            window.open(`${postLink}`, '_blank').focus();
        } else {
            router.push(
                `${postLink}?from=${encodeURIComponent(`${router.asPath}`)}`,
                `${postLink}`,
            );
        }
        // router.push(`/c/${post.communityInfo.name}/p/${post.id}/from=${}`);
    };

    // const toCreateArticle = () => {
    //     router.push('/a/edit');
    // };

    const toCreateCommunity = () => {
        router.push('/c/create');
    };

    const onPageChange = (num) => {
        console.log('[pc]', num);
        dispatch({
            type: 'article/SET_STATE',
            payload: {
                isPostInited: false,
            },
        });
        router.push(`/?pageNum=${num}`);
    };

    const onLinkClick = () => {
        dispatch({
            type: 'article/SET_STATE',
            payload: {
                isPostInited: false,
            },
        });
        router.replace(isPaginationMode ? '/' : '/?pageNum=1');
    };

    const renderFooter = () => {
        if (isPaginationMode) {
            if (pagination.currentPage === 1 && !hasMore) {
                // total is less than or equal to 10
                return (
                    <div className={styles['end-container']}>
                        暂时没有更多帖子了
                    </div>
                );
            }
            // pagination
            return (
                <div className={styles['pagination-container']}>
                    <Pagination
                        prevHref={`/?pageNum=${pagination.currentPage - 1}`}
                        nextHref={`/?pageNum=${pagination.currentPage + 1}`}
                        currentPage={pagination.currentPage}
                        total={pagination.total}
                        onChange={onPageChange}
                    />
                </div>
            );
        }
        // cursor
        return hasMore ? (
            <div ref={loaderRef} className={styles['loader-container']}>
                <Loader />
            </div>
        ) : (
            <div className={styles['end-container']}>
                暂时没有更多帖子了
            </div>
        );
    };

    return (
        <>
            <div className={styles['banner-container']} ref={banner}>
                <Helmet title="首页" />
                <div className={styles.banner}>
                    <div className={styles['banner-title']}>
                        SixChan 六叶频道是一个用户隐私为上的中文社区。
                        {
                            isDesktop && <br />
                        }
                        这里有任何你感兴趣的社区，立刻
                        <CustomLink grayVisited={false} className={styles['signup-link']} to="/signup">
                            注册
                        </CustomLink>
                        加入吧！
                    </div>
                    {/* <div className={styles['banner-title']} style={{ marginTop: 10 }}>
                        这里有任何你感兴趣的社区，立刻注册加入吧！
                    </div> */}
                    <div
                        className={styles['banner-subtitle']}
                        style={{ marginTop: isDesktop ? 30 : 20 }}
                    >
                        没有找到你感兴趣的内容？你可以
                    </div>
                    <div className={styles['banner-button-container']}>
                        {/* <Button className={styles['banner-button']} onClick={toCreateArticle}>
                            <CustomLink
                                grayVisited={false}
                                className={styles['banner-button-text']}
                                to="/a/edit">
                                投稿
                            </CustomLink>
                        </Button> */}
                        <Button className={styles['banner-button']} onClick={toCreateCommunity}>
                            <CustomLink grayVisited={false} className={styles['banner-button-text']} to="/c/create">
                                创建社区
                            </CustomLink>
                        </Button>
                    </div>
                </div>
                {
                    isDesktop && (
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                transform: 'rotate(-10deg)',
                            }}
                        >
                            <Image
                                local
                                alt="banner"
                                src={LeavesImage}
                                // src={Banner}
                                width={BannerWidth}
                                height={BannerHeight}
                            />
                        </div>
                    )
                }
            </div>
            <div className={styles.container}>
                <div className={`${styles['left-container']} ${isDesktop ? styles.desktop : styles.mobile}`}>
                    {
                        !isDesktop && trendingCommunities && trendingCommunities.length > 0 && (
                            <div className={`${styles['community-container']} ${styles.mobile}`}>
                                <div className={styles['community-title']}>热门社区</div>
                                {
                                    to2d(trendingCommunities, 2).map((row) => (
                                        <div key={`${row[0].name}-row`} className={styles['community-info-row']}>
                                            {
                                                row.map((c, index) => (
                                                    <div
                                                        key={c.name}
                                                        className={`${styles['community-info-item']} ${styles.mobile}`}
                                                    >
                                                        <CommunityInfo
                                                            iconImage={c.iconImage}
                                                            name={c.name}
                                                            alias={c.alias}
                                                            description={c.description}
                                                            trendingScore={c.trendingScore}
                                                            rank={index + 1}
                                                            isDesktop={false}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                    {
                        posts && (
                            <div className={`${styles['articles-container']}`}>
                                <div
                                    className={styles['tab-container']}
                                    style={{
                                        borderTop: '0.5px solid #d8d8d8',
                                        // borderBottom: '0.5px solid #d8d8d8',
                                        ...(isDesktop && { borderLeft: '0.5px solid #d8d8d8' }),
                                        ...(isDesktop && { borderRight: '0.5px solid #d8d8d8' }),
                                    }}
                                >
                                    <div>
                                        <Button
                                            className={`${styles['tab-button']} ${sort === 'hot' ? styles.active : ''}`}
                                            onClick={() => { changeSortType('hot'); }}
                                        >
                                            热门
                                        </Button>
                                        <Button
                                            className={`${styles['tab-button']} ${sort === 'new' ? styles.active : ''}`}
                                            onClick={() => { changeSortType('new'); }}
                                        >
                                            最新
                                        </Button>
                                    </div>
                                    <NextLink
                                        linkProps={{
                                            replace: true,
                                            href: isPaginationMode ? '/' : '/?pageNum=1',
                                        }}
                                        anchorProps={{
                                            onClick: onLinkClick,
                                            className: styles['pagination-button'],
                                            title: isPaginationMode ? '滚动模式' : '分页模式',
                                        }}
                                    >
                                        <PaginationModeIcon isPaginationMode={isPaginationMode} />
                                    </NextLink>
                                    {/* <CustomLink
                                        className={styles['pagination-button']}
                                        replace
                                        grayVisited={false}
                                        title={isPaginationMode ? '滚动模式' : '分页模式'}
                                        to={isPaginationMode ? '/' : '/?pageNum=1'}
                                    >
                                        <PaginationModeIcon isPaginationMode={isPaginationMode} />
                                    </CustomLink> */}
                                </div>
                                {
                                    posts.map((post, index) => (
                                        <React.Fragment key={post.id}>
                                            {
                                                index !== 0 ? (
                                                    <div
                                                        style={{
                                                            // width: '100%',
                                                            // backgroundColor: '#f5f4f5',
                                                            backgroundColor: '#fff',
                                                            // backgroundColor:
                                                            //    'rgb(246, 246, 246)',
                                                            height: isDesktop ? 28 : 12,
                                                            ...(isDesktop && {
                                                                borderLeft: '0.5px solid #d8d8d8',
                                                            }),
                                                            ...(isDesktop && {
                                                                borderRight: '0.5px solid #d8d8d8',
                                                            }),
                                                        }}
                                                    />
                                                ) : null
                                            }
                                            <div
                                                key={post.id}
                                                style={{
                                                    // borderTop: '0.5px solid #d8d8d8',
                                                    // borderBottom: '0.5px solid #d8d8d8',
                                                    ...(isDesktop && {
                                                        borderLeft: '0.5px solid #d8d8d8',
                                                    }),
                                                    ...(isDesktop && {
                                                        borderRight: '0.5px solid #d8d8d8',
                                                    }),
                                                    // marginTop: index === 0 ? 0 : 30,
                                                }}
                                                className={styles['article-container']}
                                            >
                                                <PostItem
                                                    id={post.id}
                                                    title={post.title}
                                                    content={post.content}
                                                    createdAt={post.createdAt}
                                                    communityInfo={post.communityInfo}
                                                    // subtitle={article.subtitle}
                                                    // headerImage={article.headerImage}
                                                    // tags={article.tags}
                                                    upvotes={post.upvotes}
                                                    downvotes={post.downvotes}
                                                    subpostCount={post.subpostCount}
                                                    isDesktop={isDesktop}
                                                    onPress={() => toPost(post)}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))
                                }
                                { renderFooter() }
                            </div>
                        )
                    }
                </div>
                {
                    isDesktop && (
                        <div className={styles['side-container']}>
                            {
                                trendingCommunities && trendingCommunities.length > 0 && (
                                    <div
                                        className={styles['community-container']}
                                    >
                                        <div className={styles['community-title']}>热门社区</div>
                                        {
                                            trendingCommunities.map((c, index) => (
                                                <div key={c.name} className={styles['community-info-item']}>
                                                    <CommunityInfo
                                                        iconImage={c.iconImage}
                                                        name={c.name}
                                                        alias={c.alias}
                                                        // description={c.description}
                                                        trendingScore={c.trendingScore}
                                                        rank={index + 1}
                                                        isDesktop
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            <SideFooter style={{ marginTop: 25 }} />
                        </div>
                    )
                }
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    posts: state.article.posts,
    trendingCommunities: state.article.trendingCommunities,
    hasMore: state.article.hasMore,
    sort: state.article.sort,
    pagination: state.article.pagination,
    isPostInited: state.article.isPostInited,
    isCommunityInited: state.article.isCommunityInited,
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    getTrendingCommunities: () => dispatch(articleActions.getTrendingCommunities()),
    getPosts: (params) => dispatch(articleActions.getPosts(params)),
    getPagedPosts: (params) => dispatch(articleActions.getPagedPosts(params)),
    countNotifications: () => dispatch(notificationActions.countNotifications()),
    dispatch,
});

Articles.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({})),
    trendingCommunities: PropTypes.arrayOf(PropTypes.shape({})),
    hasMore: PropTypes.bool,
    sort: PropTypes.oneOf(['new', 'hot']),
    pagination: PropTypes.shape({
        currentPage: PropTypes.number,
        total: PropTypes.number,
    }),
    getPosts: PropTypes.func,
    getPagedPosts: PropTypes.func,
    // getTrendingCommunities: PropTypes.func,
    countNotifications: PropTypes.func,
    dispatch: PropTypes.func,
    isLoggedIn: PropTypes.bool,
};

Articles.defaultProps = {
    posts: null,
    trendingCommunities: [],
    hasMore: false,
    sort: 'hot',
    pagination: {
        currentPage: 1,
        total: 0,
    },
    getPosts: () => {},
    getPagedPosts: () => {},
    // getTrendingCommunities: () => {},
    countNotifications: () => {},
    dispatch: () => {},
    isLoggedIn: false,
};

Articles.getInitialProps = wrapper.getInitialPageProps((store) => async (ctx) => {
    const { query } = ctx;
    const { pageNum } = query;
    const isPaginationMode = /^\d+$/.test(pageNum);
    const {
        // trendingCommunities,
        isPostInited,
        isCommunityInited,
    } = store.getState().article;
    const getPostsMethod = () => {
        if (isPostInited) return null;
        return isPaginationMode
            ? store.dispatch(articleActions.getPagedPosts({ pageNum: +pageNum }))
            : store.dispatch(articleActions.getPosts({ init: true }));
    };

    const getTrendingCommunitiesMethod = () => {
        if (isCommunityInited) return null;
        return store.dispatch(articleActions.getTrendingCommunities());
    };

    await getUserInfo({ ctx, store });
    await Promise.all([
        getPostsMethod(),
        getTrendingCommunitiesMethod(),
    ]);
});

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
