// extract image src, width, height & alt from html string
const extractImage = (html) => {
    const props = ['src', 'width', 'height', 'alt'];
    let matchedImageStr = '';
    const rt = {};
    for (let i = 0; i < props.length; i += 1) {
        const curProp = props[i];
        const curReg = new RegExp(`<img.*?${curProp}="(.*?)"[^>]+>`);
        const matches = html.match(curReg);
        if (Array.isArray(matches) === false || matches.length < 2) {
            // no match
            if (curProp === 'src') {
                return rt;
            }
        } else if (curProp === 'src') {
            // matched
            const [src, prop] = matches;
            matchedImageStr = src;
            rt[curProp] = prop;
        } else if (matchedImageStr === matches[0]) {
            // make sure the other props are
            // from the same img as src
            const [, prop] = matches;
            rt[curProp] = prop;
        }
    }
    return rt;
};

export default extractImage;
