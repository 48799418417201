const to2d = (array, colNum) => {
    const newArray = [];
    let temp = [];
    for (let i = 0; i < array.length; i += 1) {
        temp.push(array[i]);
        if (
            temp.length === colNum
            || (i === array.length - 1 && temp.length > 0)
        ) {
            newArray.push(temp);
            temp = [];
        }
    }
    return newArray;
};

export default to2d;
